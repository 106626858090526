import React, { useState } from "react"
import cx from "classnames"
import styles from "./advantages.module.css"

const FormatTitle = ({ accent, title }) => {
  return (
    <p className={cx(styles.cardHeading, styles.cardHeadingDark)}>
      <span className={cx(styles.cardHeading, styles.cardHeadingGreen)}>{accent}</span>
      <br />
      {title}
    </p>
  )
}

export const AdvantageCard = ({ imgSrc, accent, title, description }) => {
  const [isItemHidden, setIsItemHidden] = useState(true)

  return (
    <div className={styles.advantageCard}>
      <div className={styles.cardHeader}>
        <div className={styles.icon}>
          <img src={imgSrc} alt="icon" />
        </div>
        <div>
          <FormatTitle accent={accent} title={title} />
        </div>
        <div className={styles.cardButton}>
          <button
            className={cx(
              styles.button,
              `${isItemHidden ? styles.activeButton : ""}`
            )}
            aria-label={isItemHidden ? "Show details" : "Hidden details"}
            onClick={() => {
              setIsItemHidden(!isItemHidden)
            }}
          />
        </div>
      </div>
      <div
        className={cx(
          styles.cardBody,
          `${isItemHidden ? styles.hiddenItem : ""}`
        )}
      >
        <p className={styles.cardText}>{description}</p>
      </div>
    </div>
  )
}
