import React from "react";
import styles from './video-block.module.css'

export const VideoBlock = () => {

  return (
    <div className={styles.videoBlock}>
      <iframe className={styles.video} width="100%" height="100%" src="https://www.youtube.com/embed/EJU6Jf1d1XQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  )
}