import React from "react"
import cx from "classnames"
import { useTranslation } from "react-i18next"

import styles from "./cooking-process.module.css"
import Slider from "react-slick"
import icon1 from "./icons/icon1.svg"
import icon2 from "./icons/icon2.svg"
import icon3 from "./icons/icon3.svg"
import icon4 from "./icons/icon4.svg"

export const CookingProcess = () => {
  const settings = {
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  const { t } = useTranslation()

  return (
    <Slider
      {...settings}
      className={cx("cooking-carousel", styles.carouselList)}
    >
      <div className={styles.carouselItem}>
        <img src={icon1} alt="icon" />
        <p className={styles.itemTitle}>
          {t("howDoWePrepare.cookingProcess.option1Title")}
        </p>
        <p>{t("howDoWePrepare.cookingProcess.option1Desc")}</p>
      </div>

      <div className={styles.carouselItem}>
        <img src={icon2} alt="icon" />
        <p className={styles.itemTitle}>
          {t("howDoWePrepare.cookingProcess.option2Title")}
        </p>
        <p>{t("howDoWePrepare.cookingProcess.option2Desc")}</p>
      </div>

      <div className={styles.carouselItem}>
        <img src={icon3} alt="icon" />
        <p className={styles.itemTitle}>
          {t("howDoWePrepare.cookingProcess.option3Title")}
        </p>
        <p>{t("howDoWePrepare.cookingProcess.option3Desc")}</p>
      </div>

      <div className={styles.carouselItem}>
        <img src={icon4} alt="icon" />
        <p className={styles.itemTitle}>
          {t("howDoWePrepare.cookingProcess.option4Title")}
        </p>
        <p>{t("howDoWePrepare.cookingProcess.option4Desc")}</p>
      </div>
    </Slider>
  )
}
