import React from 'react'
import hero_desk from './hero_desk.png'
import hero_mob from './hero_mob.png'

import styles from './hero.module.css'

export const Hero = () => {
  return (
    <section className={styles.hero}>
      <img src={hero_desk} className={styles.heroDesktop} alt="hero image" />
      <img src={hero_mob} className={styles.heroMobile} alt="hero image" />
    </section>
  )
}
