import React from "react"
import cx from "classnames"

import { VideoBlock } from "../video-block"
import { Container } from "../container"
import { CookingProcess } from "../cooking-process"
import { Trans } from "react-i18next"

import styles from "./how-do-we-prepare.module.css"

export const HowDoWePrepare = () => {
  return (
    <section className={styles.section}>
      <Container isWide={true}>
        <h3
          className={cx(styles.sectionTitle, "fancyUnderlineText text-center")}
        >
          <Trans i18nKey="howDoWePrepare.title">
            Jak <span>připravujeme</span> naše jídla?
          </Trans>
        </h3>
        <p className={cx(styles.sectionSubtitle, "text-center")}>
          <Trans i18nKey="howDoWePrepare.description">
            Podívejte se na unikátní proces přípravy našich skvělých jídel.
          </Trans>
        </p>
        <div className={styles.contentBlock}>
          <VideoBlock />
          <CookingProcess />
        </div>
      </Container>
    </section>
  )
}
