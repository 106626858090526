import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import { Trans, useTranslation } from 'react-i18next';

import { AnimatedWrapper } from '../animated-wrapper';
import { Container } from '../container';
import styles from './delivery.module.css';
import mapImgMob from './NP_web_assets/map_mob.jpg';
import mapImg from './NP_web_assets/map_dektop.jpg';
import mapImgMobEn from './NP_web_assets/map-mob_en.png';
import mapImgEn from './NP_web_assets/map_en.png';

export const DeliverySection = ({id}) => {
  const { t } = useTranslation()
  const isEn = document.location.pathname.includes("/en")

  return (
    <section id={id && id} className={styles.delivery}>
      <Container isWide={true}>
        {/* <AnimatedWrapper> */}
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          <Trans i18nKey="home.delivery.title">
            Doprava po Praze <span>zdarma</span>
          </Trans>
        </h3>
        <img src={Map} style={{ display: "block" }} />
        <div className={styles.deliveryContent}>
          <div className={styles.deliveryImages}>
            <img
              src={isEn ? mapImgEn : mapImg}
              className={styles.deliveryImageDesktop}
              alt="car picture"
            />
            <img
              src={isEn ? mapImgMobEn : mapImgMob}
              className={styles.deliveryImageMobile}
              alt="map picture"
            />
          </div>
          <div className={styles.deliveryListWrap}>
            <div>
              <h3 className={styles.deliveryListTitle}>
                {t("home.delivery.listTitle")}
              </h3>
              <ul className={styles.deliveryList}>
                <li>
                  <Trans i18nKey="home.delivery.listOption1">
                    Po Praze <strong>za dovoz neplatíš.</strong>
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="home.delivery.listOption2Blueghost">
                    Vybereš si <strong>2 hodinové okno,</strong> už žádné celovečení nahánění s kurýry!
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="home.delivery.listOption3Blueghost">
                    Změnily se ti plány? Čas a místo si můžeš flexibilně změnit ještě v den dovozu do 12:00.
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="home.delivery.listOption4Blueghost">
                    Nevyhovují ti časy dovozu? Na dotaz ti nabídneme jedno z našich <strong>9 odběrných míst.</strong>
                  </Trans>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className={styles.pointsListWrap}>
            <div>
              <h3 className={styles.deliveryListTitle}>
                {t("home.points.listTitle")}
              </h3>
              <ul className={styles.pointsList}>
                <li><span>1</span><p>{t("home.points.listOption1")}</p>{t("home.points.listOption1-1")}</li>
                <li><span>2</span><p>{t("home.points.listOption2")}</p>{t("home.points.listOption2-1")}</li>
                <li><span>3</span><p>{t("home.points.listOption3")}</p>{t("home.points.listOption3-1")}</li>
                <li><span>4</span><p>{t("home.points.listOption4")}</p>{t("home.points.listOption4-1")}</li>
                <li><span>5</span><p>{t("home.points.listOption5")}</p>{t("home.points.listOption5-1")}</li>
                <li><span>6</span><p>{t("home.points.listOption6")}</p>{t("home.points.listOption6-1")}</li>
                <li><span>7</span><p>{t("home.points.listOption7")}</p>{t("home.points.listOption7-1")}</li>
                <li><span>8</span><p>{t("home.points.listOption8")}</p>{t("home.points.listOption8-1")}</li>
                <li><span>9</span><p>{t("home.points.listOption9")}</p>{t("home.points.listOption9-1")}</li>
                <li><span>10</span><p>{t("home.points.listOption10")}</p>{t("home.points.listOption10-1")}</li>
                <li><span>11</span><p>{t("home.points.listOption11")}</p>{t("home.points.listOption11-1")}</li>
                <li><span>12</span><p>{t("home.points.listOption12")}</p>{t("home.points.listOption12-1")}</li>
              </ul>
            </div>
          </div> */}
        </div>
        {/* </AnimatedWrapper> */}
      </Container>
    </section>
  )
}
