import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { DiscountSection } from '../components/discount'
import { FAQ2 } from '../components/faq2'
import { FoodCarouselSection3 } from '../components/food3'
import { Hero } from '../components/hero-specialni-nabidka'
import { HowItWork2 } from '../components/howitwork-v2'
import { Loader } from '../components/loader'
import { MenuOrderInfo } from '../components/menuOrderInfo'
import { NutritionistSection } from '../components/nutritionist'
import { Order2 } from '../components/order-form2'
import { RedirectModal } from '../components/redirect-modal'
import { SaleFormModal } from '../components/sale-form-modal'
import { SaleFormModal2 } from '../components/sale-form-modal2'
import SEO from '../components/seo'
import { AffiliateContext } from '../contexts/AffiliateContext'
import Advantages from '../components/advantages'
import { VegeMenuBlock } from '../components/vege-menu'
import { DeliverySection } from '../components/delivery'
import { HowDoWePrepare } from '../components/how-do-we-prepare'
import { Program2 } from '../components/program-v2'
import { Reviews2 } from '../components/reviews2'
import { FoodCarouselNovinky } from '../components/food-novinky'

const PageLayout = ({ site }) => {
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate


  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer &&
  //   window.dataLayer.push({
  //     pageURL: "/specialni-nabidka",
  //     testovani: websiteVersion,
  //   })
  // }, [websiteVersion])


  return (
    <>
      <SEO title="Home" />
      {!isAffiliate && <SaleFormModal formName="sale-form" />}
      <RedirectModal />
      <SaleFormModal2 formName="Akce 2 dny" />
      <Hero />
      <FoodCarouselNovinky />
      <div id="calculator2" />
      <Order2 id="calculator" />
      <Advantages />
      <Program2 />
      <MenuOrderInfo id="menu" />
      <FoodCarouselSection3 />
      <VegeMenuBlock id="vege-menu-section" />
      <NutritionistSection />
      <DeliverySection id="delivery-section" />
      <Reviews2 />
      <HowItWork2 />
      <HowDoWePrepare />
      <DiscountSection />
      <FAQ2 />
    </>
  )
}

const SpecialniNabidka = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  return (
    <>
      {site ? <PageLayout site={site} isAffiliate={isAffiliate} /> : <Loader />}
    </>
  )
}

export default SpecialniNabidka
