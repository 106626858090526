import React from "react"

import { Trans, useTranslation } from "react-i18next"
import styles from "./vege-menu.module.css"
import { ContactFormNew } from "../forms/contact-form-new"
import { IconCheck } from "./images/icons"
import { VegeIcon } from "./images/icons"

export const VegeMenuBlock = ({ id }) => {
  const { t } = useTranslation()

  return (
    <section id={id && id} className={styles.vegeMenu}>
      <h3
        style={{ margin: "0" }}
        className="fancyUnderlineText sectionTitleNew text-center"
      >
        <Trans i18nKey="home.vegeMenuTitle">
          <span>wegetariańskie</span> menu
        </Trans>
      </h3>

      <div className={styles.vegeMenuText}>
        <p>{t("home.vegeMenuSubtitle1")}</p>
      </div>

      <div className={styles.infoBlock}>
        <div className={styles.infoBlockWrap}>
          <div className={styles.infoBlockCol}>
            <VegeIcon />
            <h4 className={styles.infoBlockTitle}>
              {t("home.vegeMenuDescription")}
            </h4>
            <ul className={styles.infoList}>
              <li className={styles.infoListItem}>
                <IconCheck />
                <span>
                  {t("home.vegeMenuPar1")}
                </span>
              </li>
              <li className={styles.infoListItem}>
                <IconCheck />
                <span>
                  {t("home.vegeMenuPar2")}
                </span>
              </li>
              <li className={styles.infoListItem}>
                <IconCheck />
                <span>
                   {t("home.vegeMenuPar3")}
                </span>
              </li>
              <li className={styles.infoListItem}>
                <IconCheck />
                <span>
                  {t("home.vegeMenuPar4")}
                </span>
              </li>
            </ul>
          </div>

          <div className={styles.infoBlockCol}>
            <h4 className={styles.infoBlockColTitle}>{t("home.vegeMenuSaleTitle")}</h4>
            <p>{t("home.vegeMenuFormDesc")}</p>
            <p className={styles.contactFormLabel}>
              {t("home.vegeMenuFormLabel")}
              <span>*</span>
            </p>
            <ContactFormNew
              className={styles.contactForm}
              btnText="Proszę o kontakt"
              form_name={"vege-objednavka"}
            />
            {/* <div className={styles.iconDiscount}><IconDiscount /></div> */}
          </div>
        </div>
      </div>
    </section>
  )
}
