import React from "react"
import { AdvantageCard } from "./advantage-card"
import styles from "./advantages.module.css"
import icon1 from "./icons/icon1.svg"
import icon2 from "./icons/icon2.svg"
import icon3 from "./icons/icon3.svg"
import icon4 from "./icons/icon4.svg"

export const Advantages = () => {
  return (
    <section className={styles.advantagesView}>
      <div className={styles.advantages}>
        <div className={styles.advantagesWrapper}>
          <AdvantageCard
            imgSrc={icon1}
            accent={"Najbardziej "}
            title={"precyzyjny czas dostawy"}
            description={
              "\n" +
              "Dostarczamy w dwugodzinowych oknach czasowych, które możesz \n" +
              "dowolnie zmieniać. Jedzenie dostarczymy bezpośrednio pod drzwi \n" +
              "w wybranym przez Ciebie czasie."
            }
          />
          <AdvantageCard
            imgSrc={icon2}
            accent={"Najbardziej "}
            title={"elastyczny darmowy transport."}
            description={"Nie czekaj cały wieczór na kuriera. \n" +
              "Czas i miejsce dostawy możesz elastycznie dostosować \n" +
              "aż do ostatniej chwili. Po prostu zadzwoń lub napisz."}
          />
          <AdvantageCard
            imgSrc={icon3}
            accent={"U nas "}
            title={"95% klientów smakuje"}
            description={
              "95% klientów ocenia smak naszych dań na 5 gwiazdek. \n" +
              "Aktywnie zbieramy opinie na temat każdego dania i stale \n" +
              "udoskonalamy nasze menu."
            }
          />
          <AdvantageCard
            imgSrc={icon4}
            accent={"Profesjonalne "}
            title={"konsultacje w cenie."}
            description={
              "Od pierwszego dnia jesteś na właściwej ścieżce. \n" +
              "Nasi doradcy żywieniowi i ekspertka odżywiania będą \n" +
              "Ci bezpłatnie pomagać w osiągnięciu Twoich celów żywieniowych."
            }
          />
        </div>
      </div>
    </section>
  )
}
